<template>
  <AppPageCard>
    <template #card-title> Calendar</template>
    <template #card-toolbar>
      <b-button variant="light-primary" @click="addEvent">Create</b-button>
      <b-button variant="light-primary" @click="calendarOptions.weekends = !calendarOptions.weekends">Weekend</b-button>
    </template>

    <FullCalendar class="" :options="calendarOptions"></FullCalendar>
  </AppPageCard>
</template>

<script>
import AppPageCard from "../../../core/components/shared/card/AppPageCard";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";

export default {
  name: "CompanyCalendarPage",
  components: { AppPageCard, FullCalendar },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        weekends: true,
        themeSystem: "bootstrap",
        events: [
          { title: "event 1", date: "2021-12-17" },
          { title: "event 2", date: "2021-12-18" },
        ],
      },
    };
  },

  methods: {
    addEvent() {
      this.calendarOptions.events.push({
        title: "Test 123",
        start: "2021-12-01",
        end: "2021-12-07",
      });
    },
  },
};
</script>

<style scoped></style>
